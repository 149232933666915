import { $$ } from './helper/utils';
import { MOBILMEDIA } from './helper/constants';
import { slider } from './components/bannerSlider';
import inView from './helper/inView';

export default function () {
  let mobileNavLoaded = false;
  let desktopNavLoaded = false;
  // let sliderLoaded = false;
  slider();

  const loadmobileNav = () => {
    import('./components/mobileNav').then((mobileNav) => {
      mobileNavLoaded = true;
      return mobileNav.init();
    }).catch((err) => {
      console.warn('Chunk mobileNav.js loading failed', err);
    });
  };

  const loadDesktopNav = () => {
    import('./components/navigation').then((navigation) => {
      desktopNavLoaded = true;
      return navigation.init();
    }).catch((err) => {
      console.warn('Chunk navigation.js loading failed', err);
    });
  };

  // const loadSlider = () => {
  //   if (document.querySelector('.mobile-slider') !== null) {
  //     import('./components/slider').then((mobileSlider) => {
  //       sliderLoaded = true;
  //       return mobileSlider.mobileSlider();
  //     }).catch((err) => {
  //       console.warn(`Chunk slider.js loading failed: ${err}`);
  //     });
  //   }
  // };

  // conditionaly load scripts that are only needed for certain viewports
  // but only if the viewport changed
  MOBILMEDIA.addListener((e) => {
    if (e.matches) {
      if (mobileNavLoaded === false) loadmobileNav();
    } else {
      // eslint-disable-next-line no-lonely-if
      if (desktopNavLoaded === false) loadDesktopNav();
    }
  });

  // PHONEMEDIA.addListener(e => {
  //     if (e.matches) {
  //         if (sliderLoaded === false) loadSlider();
  //     }
  // });

  // conditionaly load scripts that are only needed for certain viewports
  if (MOBILMEDIA.matches) {
    loadmobileNav();
  } else {
    loadDesktopNav();
  }

  if (document.querySelector('.custom-select') !== null) {
    import('./components/select')
      .then((select) => select.init())
      .catch((err) => {
        console.warn(`Chunk select.js loading failed: ${err}`);
      });
  }

  if (document.querySelector('.ratingwrapper') !== null) {
    import('./components/rate')
      .then((rate) => rate.init())
      .catch((err) => {
        console.warn(`Chunk rate.js loading failed: ${err}`);
      });
  }

  if (document.querySelector('.recipe-page') !== null) {
    import('./pages/recipes')
      .then((recipes) => recipes.init())
      .catch((err) => {
        console.warn(`Chunk recipes.js loading failed: ${err}`);
      });
  }

  inView($$('main video'), () => {
    import('./components/videos/video')
      .catch((err) => {
        console.warn('Chunk videos/video.js loading failed', err);
      });
  }, true);

  if (document.querySelector('.recipe-slider') !== null) {
    import('./components/recipeSlider').then((recipeSlider) => recipeSlider.default()).catch((err) => {
      console.warn(`Chunk recipes.js loading failed: ${err}`);
    });
  }

  if (document.querySelector('.nav-tabs') !== null || document.querySelector('.accordion') !== null) {
    import('../libs/bs-4-native').then((bsn) => {
      if ('bootstrapNative' in window === false) {
        window.bootstrapNative = bsn;
      }
      const tabs = document.querySelectorAll('.nav-tabs .tab-link');
      for (let i = 0; i < tabs.length; i++) {
        const el = tabs[i];
        // fade in new tab content
        el.addEventListener('hide.bs.tab', (e) => {
          const href = e.relatedTarget.getAttribute('href');
          TweenMax.fromTo(href, 0.3, { opacity: 0 }, { opacity: 1, ease: Linear.easeNone });
        }, false);
      }
    }).catch((err) => {
      console.warn('Chunk bs4-native.js loading failed', err);
    });
  }
}
